@import (reference) '~antd/dist/antd.less';
@import (reference) '../../theme.less';

.server-error-root {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex: auto;

  .left-side {
    flex: 1;
    display: flex;
    overflow: hidden;
    background: @heading-color;

    .shapes {
      position: relative;

      .shape-1 {
        display: block;
        width: 0;
        height: 0;
        border: 88px solid transparent;
        border-left-color: @third-figure-background-color;
        position: absolute;
        top: 228px;
        left: 0;
      }

      .shape-2 {
        display: block;
        width: 0;
        height: 0;
        border: 88px solid transparent;
        border-bottom-color: @second-figure-background-color;
        position: absolute;
        bottom: 0;
        left: 0;
      }

      .shape-3 {
        display: block;
        width: 0;
        height: 0;
        border: 88px solid transparent;
        border-bottom-color: @fourth-figure-background-color;
        position: absolute;
        bottom: 88px;
        left: 88px;

        &::after {
          content: '';
          position: absolute;
          left: -88px;
          top: 88px;
          width: 0;
          height: 0;
          border: 88px solid transparent;
          border-top-color: @fourth-figure-background-color;
        }
      }

      .shape-4 {
        display: block;
        width: 0;
        height: 0;
        border: 88px solid transparent;
        border-bottom-color: @third-figure-background-color;
        position: absolute;
        bottom: 175px;
        left: calc(~'50vw - 110px');

        &::after {
          content: '';
          position: absolute;
          left: -88px;
          top: 88px;
          width: 0;
          height: 0;
          border: 88px solid transparent;
          border-top-color: @third-figure-background-color;
        }
      }

      .shape-5 {
        display: block;
        width: 0;
        height: 0;
        border: 88px solid transparent;
        border-top-color: @second-figure-background-color;
        position: absolute;
        top: 0;
        left: calc(~'50vw - 80px');
      }
    }

    .content {
      display: flex;
      padding-top: 135px;
      padding-bottom: 225px;
      flex: 1;

      .main-part {
        margin: 0 auto;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .label {
          font-size: 20px;
          z-index: 1;
          padding-bottom: 10px;

          :first-child {
            font-weight: 600;
            color: @primary-color;
          }

          :last-child {
            font-weight: 100;
            color: @text-primary-color;
          }
        }

        .message {
          .title {
            font-size: 72px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 0.63;
            letter-spacing: normal;
            text-align: left;
            color: @text-accent-color;
            z-index: 1;
            padding-bottom: 40px;
          }

          .text {
            font-size: 28px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.61;
            letter-spacing: normal;
            text-align: left;
            color: @text-accent-color;
            z-index: 1;
            padding-bottom: 35px;
          }

          .error {
            font-size: 18px;
            font-weight: 500;
            color: @text-accent-color;
            z-index: 1;
            padding-bottom: 35px;

            :last-child {
              color: @tt-primary-color;
            }
          }
        }

        .server-error-button {
          position: relative;
          z-index: 1;
          width: 75%;
          background-color: @tt-primary-color;
          border-color: @tt-primary-color;
          border-radius: 4px;
          min-height: 48px;
          padding: 0;
          display: flex;

          a {
            display: flex;
            min-height: 46px;
            width: 100%;
            padding-left: 44px;
            align-items: center;
            font-size: 16px;
            font-weight: 600;
            color: @heading-color;
          }

          i {
            position: absolute;
            top: 15px;
            right: 27px;
            font-size: 16px;
            font-weight: 600;
            color: @heading-color;
          }
        }
      }
    }
  }

  .right-side {
    flex: 1;
    display: flex;
    background-image: url('/images/500-image.jpg');
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.5;
  }
}

@media (max-width: 992px) {
  .server-error-root {
    .left-side {
      .shapes {
        .shape-4 {
          display: none;
        }
      }

      .content {
        .main-part {
          .server-error-button {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .server-error-root {
    .left-side {
      .shapes {
        .shape-5 {
          left: calc(~'100vw - 80px');
        }
      }
    }

    .right-side {
      display: none;
    }
  }
}

@media (max-width: 600px) {
  .server-error-root {
    .left-side {
      .shapes {
        .shape-1,
        .shape-5 {
          display: none;
        }
      }

      .content {
        .main-part {
          width: 80%;
        }
      }
    }
  }
}

@media (max-height: 840px) {
  .server-error-root {
    .left-side {
      .shapes {
        .shape-1 {
          display: none;
        }
      }

      .content {
        padding-top: 20px;
      }
    }
  }
}

@media (max-height: 667px) {
  .server-error-root {
    .left-side {
      .content {
        padding-top: 10px;
        padding-bottom: 100px;

        .main-part {
          .message {
            .title {
              padding-bottom: 20px;
            }

            .text {
              padding-bottom: 10px;
            }

            .error {
              padding-bottom: 10px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-height: 667px) and (orientation: landscape) {
  .server-error-root {
    height: auto;

    .left-side {
      .shapes {
        .shape-1,
        .shape-2,
        .shape-3,
        .shape-4,
        .shape-5 {
          display: none;
        }
      }

      .content {
        padding-top: 20px;
        padding-bottom: 20px;

        .main-part {
          justify-content: start;
          overflow-y: auto;

          .label {
            padding-bottom: 20px;
          }

          .message {
            .title {
              padding-bottom: 30px;
            }

            .text {
              padding-bottom: 20px;
            }

            .error {
              padding-bottom: 20px;
            }
          }
        }
      }
    }

    .right-side {
      display: none;
    }
  }
}
