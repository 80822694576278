.layout .sider {
  box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.02), inset -2px 0 0 0 #ecedf2;
  background-color: #ffffff;
}
.layout .sider .trigger {
  padding: 30px;
  font-size: 16px;
}
.layout .nav .ant-menu-item {
  width: calc(99%);
}
.layout .nav .ant-menu-item::after {
  border-color: #acc437;
  right: auto;
  left: 0;
}
.layout .nav .ant-menu-item span {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  color: #9da6c0;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  vertical-align: middle;
}
.layout .nav .ant-menu-item .anticon {
  font-size: 16px;
  vertical-align: middle;
}
.layout .nav .ant-menu-item.ant-menu-item-selected {
  background-color: #f4f7fc;
}
.layout .nav .ant-menu-item.ant-menu-item-selected .icon svg {
  fill: #acc437;
}
.layout .nav .ant-menu-item.ant-menu-item-selected.ant-menu-item::after {
  border-color: #acc437;
  -webkit-transform: none;
          transform: none;
  opacity: 1;
  right: auto;
  left: 0;
}
.layout .nav.ant-menu-inline .ant-menu-item::after {
  right: auto;
  left: 0;
}
.layout .header {
  background-color: #ffffff;
  padding-left: 0;
  padding-right: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  position: relative;
}
@media screen and (max-width: 576px) {
  .layout .header {
    padding-left: 5px;
  }
}
.layout .header::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: transparent;
  box-shadow: 0 5px 15px 0 black;
  z-index: 30;
}
.layout .header .header-first-section {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .layout .header .header-first-section {
    flex-basis: 100%;
  }
}
.layout .header .header-first-section .icon.trigger {
  width: 80px;
}
@media screen and (max-width: 576px) {
  .layout .header .header-first-section .icon.trigger {
    width: 35px;
    padding-left: 5px;
    text-align: left;
  }
}
.layout .header .header-first-section .header-search-area {
  padding-right: calc(13px);
}
.layout .header .header-second-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  border: 2px solid #ecedf2;
  border-top: none;
  border-bottom: none;
  padding-left: 25px;
  padding-right: 25px;
}
@media screen and (max-width: 768px) {
  .layout .header .header-second-section {
    padding-left: 7px;
    padding-right: 7px;
    border-left: none;
  }
}
@media screen and (max-width: 576px) {
  .layout .header .header-second-section {
    border-right: none;
    padding-right: 0;
  }
}
.layout .header .header-second-section .header__user-name {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2d3039;
  margin-right: 10px;
  margin-left: 10px;
}
@media screen and (max-width: 768px) {
  .layout .header .header-second-section .header__user-name {
    display: none;
  }
}
.layout .header .header-second-section .icon img {
  padding: 4px 0;
}
.layout .header .header-second-section .ant-avatar > img {
  object-fit: cover;
}
.layout .header .header-last-section:not(:empty) {
  padding-left: 15px;
  padding-right: 10px;
}
@media screen and (max-width: 576px) {
  .layout .header .header-last-section:not(:empty) {
    padding-left: 5px;
    padding-right: 0;
  }
}
.layout .header .company-logo-link:focus {
  text-decoration: none;
}
.layout .header .company-logo {
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.5;
  letter-spacing: normal;
  text-align: left;
  color: #acc437;
}

.back-to-origin .ant-drawer-title {
  color: #2d3039;
}
.back-to-origin .ant-drawer-body {
  padding: 0;
}
.back-to-origin .ant-card-meta {
  padding-bottom: 10px;
}
.back-to-origin .ant-radio-wrapper {
  padding-bottom: 10px;
}
.back-to-origin .ant-btn {
  margin-top: 10px;
}
.back-to-origin .ant-typography,
.back-to-origin .ant-card-meta-title,
.back-to-origin .ant-card-meta-detail {
  color: #808d93;
}

.btn-default.inactive {
  pointer-events: none;
  opacity: 0.5;
}
.btn-default.inactive:hover {
  background: transparent;
}

#page-spinner {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 100000;
  position: fixed;
}
.spinner-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.spinner-wrapper .spinner-title {
  color: #2d3039;
  font-size: 16px;
  font-weight: 600;
  padding-top: 15px;
}

.server-error-root {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex: auto;
}
.server-error-root .left-side {
  flex: 1 1;
  display: flex;
  overflow: hidden;
  background: #ffffff;
}
.server-error-root .left-side .shapes {
  position: relative;
}
.server-error-root .left-side .shapes .shape-1 {
  display: block;
  width: 0;
  height: 0;
  border: 88px solid transparent;
  border-left-color: #f85334;
  position: absolute;
  top: 228px;
  left: 0;
}
.server-error-root .left-side .shapes .shape-2 {
  display: block;
  width: 0;
  height: 0;
  border: 88px solid transparent;
  border-bottom-color: #bed738;
  position: absolute;
  bottom: 0;
  left: 0;
}
.server-error-root .left-side .shapes .shape-3 {
  display: block;
  width: 0;
  height: 0;
  border: 88px solid transparent;
  border-bottom-color: #f1f4f9;
  position: absolute;
  bottom: 88px;
  left: 88px;
}
.server-error-root .left-side .shapes .shape-3::after {
  content: '';
  position: absolute;
  left: -88px;
  top: 88px;
  width: 0;
  height: 0;
  border: 88px solid transparent;
  border-top-color: #f1f4f9;
}
.server-error-root .left-side .shapes .shape-4 {
  display: block;
  width: 0;
  height: 0;
  border: 88px solid transparent;
  border-bottom-color: #f85334;
  position: absolute;
  bottom: 175px;
  left: calc(50vw - 110px);
}
.server-error-root .left-side .shapes .shape-4::after {
  content: '';
  position: absolute;
  left: -88px;
  top: 88px;
  width: 0;
  height: 0;
  border: 88px solid transparent;
  border-top-color: #f85334;
}
.server-error-root .left-side .shapes .shape-5 {
  display: block;
  width: 0;
  height: 0;
  border: 88px solid transparent;
  border-top-color: #bed738;
  position: absolute;
  top: 0;
  left: calc(50vw - 80px);
}
.server-error-root .left-side .content {
  display: flex;
  padding-top: 135px;
  padding-bottom: 225px;
  flex: 1 1;
}
.server-error-root .left-side .content .main-part {
  margin: 0 auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.server-error-root .left-side .content .main-part .label {
  font-size: 20px;
  z-index: 1;
  padding-bottom: 10px;
}
.server-error-root .left-side .content .main-part .label :first-child {
  font-weight: 600;
  color: #acc437;
}
.server-error-root .left-side .content .main-part .label :last-child {
  font-weight: 100;
  color: #9da6c0;
}
.server-error-root .left-side .content .main-part .message .title {
  font-size: 72px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.63;
  letter-spacing: normal;
  text-align: left;
  color: #2d3039;
  z-index: 1;
  padding-bottom: 40px;
}
.server-error-root .left-side .content .main-part .message .text {
  font-size: 28px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.61;
  letter-spacing: normal;
  text-align: left;
  color: #2d3039;
  z-index: 1;
  padding-bottom: 35px;
}
.server-error-root .left-side .content .main-part .message .error {
  font-size: 18px;
  font-weight: 500;
  color: #2d3039;
  z-index: 1;
  padding-bottom: 35px;
}
.server-error-root .left-side .content .main-part .message .error :last-child {
  color: #0084ff;
}
.server-error-root .left-side .content .main-part .server-error-button {
  position: relative;
  z-index: 1;
  width: 75%;
  background-color: #0084ff;
  border-color: #0084ff;
  border-radius: 4px;
  min-height: 48px;
  padding: 0;
  display: flex;
}
.server-error-root .left-side .content .main-part .server-error-button a {
  display: flex;
  min-height: 46px;
  width: 100%;
  padding-left: 44px;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}
.server-error-root .left-side .content .main-part .server-error-button i {
  position: absolute;
  top: 15px;
  right: 27px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}
.server-error-root .right-side {
  flex: 1 1;
  display: flex;
  background-image: url('/images/500-image.jpg');
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.5;
}
@media (max-width: 992px) {
  .server-error-root .left-side .shapes .shape-4 {
    display: none;
  }
  .server-error-root .left-side .content .main-part .server-error-button {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .server-error-root .left-side .shapes .shape-5 {
    left: calc(100vw - 80px);
  }
  .server-error-root .right-side {
    display: none;
  }
}
@media (max-width: 600px) {
  .server-error-root .left-side .shapes .shape-1,
  .server-error-root .left-side .shapes .shape-5 {
    display: none;
  }
  .server-error-root .left-side .content .main-part {
    width: 80%;
  }
}
@media (max-height: 840px) {
  .server-error-root .left-side .shapes .shape-1 {
    display: none;
  }
  .server-error-root .left-side .content {
    padding-top: 20px;
  }
}
@media (max-height: 667px) {
  .server-error-root .left-side .content {
    padding-top: 10px;
    padding-bottom: 100px;
  }
  .server-error-root .left-side .content .main-part .message .title {
    padding-bottom: 20px;
  }
  .server-error-root .left-side .content .main-part .message .text {
    padding-bottom: 10px;
  }
  .server-error-root .left-side .content .main-part .message .error {
    padding-bottom: 10px;
  }
}
@media screen and (max-height: 667px) and (orientation: landscape) {
  .server-error-root {
    height: auto;
  }
  .server-error-root .left-side .shapes .shape-1,
  .server-error-root .left-side .shapes .shape-2,
  .server-error-root .left-side .shapes .shape-3,
  .server-error-root .left-side .shapes .shape-4,
  .server-error-root .left-side .shapes .shape-5 {
    display: none;
  }
  .server-error-root .left-side .content {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .server-error-root .left-side .content .main-part {
    justify-content: start;
    overflow-y: auto;
  }
  .server-error-root .left-side .content .main-part .label {
    padding-bottom: 20px;
  }
  .server-error-root .left-side .content .main-part .message .title {
    padding-bottom: 30px;
  }
  .server-error-root .left-side .content .main-part .message .text {
    padding-bottom: 20px;
  }
  .server-error-root .left-side .content .main-part .message .error {
    padding-bottom: 20px;
  }
  .server-error-root .right-side {
    display: none;
  }
}

.forbidden-root {
  height: 100vh;
  display: flex;
  justify-content: center;
  flex: auto;
}
.forbidden-root .left-side {
  flex: 1 1;
  display: flex;
  overflow: hidden;
  background: #ffffff;
}
.forbidden-root .left-side .shapes {
  position: relative;
}
.forbidden-root .left-side .shapes .shape-1 {
  display: block;
  width: 0;
  height: 0;
  border: 88px solid transparent;
  border-left-color: #f85334;
  position: absolute;
  top: 228px;
  left: 0;
}
.forbidden-root .left-side .shapes .shape-2 {
  display: block;
  width: 0;
  height: 0;
  border: 88px solid transparent;
  border-bottom-color: #bed738;
  position: absolute;
  bottom: 0;
  left: 0;
}
.forbidden-root .left-side .shapes .shape-3 {
  display: block;
  width: 0;
  height: 0;
  border: 88px solid transparent;
  border-bottom-color: #f1f4f9;
  position: absolute;
  bottom: 88px;
  left: 88px;
}
.forbidden-root .left-side .shapes .shape-3::after {
  content: '';
  position: absolute;
  left: -88px;
  top: 88px;
  width: 0;
  height: 0;
  border: 88px solid transparent;
  border-top-color: #f1f4f9;
}
.forbidden-root .left-side .shapes .shape-4 {
  display: block;
  width: 0;
  height: 0;
  border: 88px solid transparent;
  border-bottom-color: #f85334;
  position: absolute;
  bottom: 175px;
  left: calc(50vw - 110px);
}
.forbidden-root .left-side .shapes .shape-4::after {
  content: '';
  position: absolute;
  left: -88px;
  top: 88px;
  width: 0;
  height: 0;
  border: 88px solid transparent;
  border-top-color: #f85334;
}
.forbidden-root .left-side .shapes .shape-5 {
  display: block;
  width: 0;
  height: 0;
  border: 88px solid transparent;
  border-top-color: #bed738;
  position: absolute;
  top: 0;
  left: calc(50vw - 80px);
}
.forbidden-root .left-side .content {
  display: flex;
  padding-top: 135px;
  padding-bottom: 225px;
  flex: 1 1;
}
.forbidden-root .left-side .content .main-part {
  margin: 0 auto;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.forbidden-root .left-side .content .main-part .label {
  font-size: 20px;
  z-index: 1;
  padding-bottom: 10px;
}
.forbidden-root .left-side .content .main-part .label :first-child {
  font-weight: 600;
  color: #acc437;
}
.forbidden-root .left-side .content .main-part .label :last-child {
  font-weight: 100;
  color: #9da6c0;
}
.forbidden-root .left-side .content .main-part .message .title {
  font-size: 72px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.63;
  letter-spacing: normal;
  text-align: left;
  color: #2d3039;
  z-index: 1;
  padding-bottom: 40px;
}
.forbidden-root .left-side .content .main-part .message .text {
  font-size: 28px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.61;
  letter-spacing: normal;
  text-align: left;
  color: #2d3039;
  z-index: 1;
  padding-bottom: 35px;
}
.forbidden-root .left-side .content .main-part .message .error {
  font-size: 18px;
  font-weight: 500;
  color: #2d3039;
  z-index: 1;
  padding-bottom: 35px;
}
.forbidden-root .left-side .content .main-part .message .error :last-child {
  color: #0084ff;
}
.forbidden-root .left-side .content .main-part .forbidden-button {
  position: relative;
  z-index: 1;
  width: 75%;
  background-color: #0084ff;
  border-color: #0084ff;
  border-radius: 4px;
  min-height: 48px;
  padding: 0;
  display: flex;
}
.forbidden-root .left-side .content .main-part .forbidden-button a {
  display: flex;
  min-height: 46px;
  width: 100%;
  padding-left: 44px;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}
.forbidden-root .left-side .content .main-part .forbidden-button i {
  position: absolute;
  top: 15px;
  right: 27px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
}
.forbidden-root .right-side {
  flex: 1 1;
  display: flex;
  background-image: url('/images/403-image.jpg');
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.5;
}
@media (max-width: 992px) {
  .forbidden-root .left-side .shapes .shape-4 {
    display: none;
  }
  .forbidden-root .left-side .content .main-part .forbidden-button {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .forbidden-root .left-side .shapes .shape-5 {
    left: calc(100vw - 80px);
  }
  .forbidden-root .right-side {
    display: none;
  }
}
@media (max-width: 600px) {
  .forbidden-root .left-side .shapes .shape-1,
  .forbidden-root .left-side .shapes .shape-5 {
    display: none;
  }
  .forbidden-root .left-side .content .main-part {
    width: 80%;
  }
}
@media (max-height: 840px) {
  .forbidden-root .left-side .shapes .shape-1 {
    display: none;
  }
  .forbidden-root .left-side .content {
    padding-top: 20px;
  }
}
@media (max-height: 667px) {
  .forbidden-root .left-side .content {
    padding-top: 10px;
    padding-bottom: 100px;
  }
  .forbidden-root .left-side .content .main-part .message .title {
    padding-bottom: 20px;
  }
  .forbidden-root .left-side .content .main-part .message .text {
    padding-bottom: 10px;
  }
  .forbidden-root .left-side .content .main-part .message .error {
    padding-bottom: 10px;
  }
}
@media screen and (max-height: 667px) and (orientation: landscape) {
  .forbidden-root {
    height: auto;
  }
  .forbidden-root .left-side .shapes .shape-1,
  .forbidden-root .left-side .shapes .shape-2,
  .forbidden-root .left-side .shapes .shape-3,
  .forbidden-root .left-side .shapes .shape-4,
  .forbidden-root .left-side .shapes .shape-5 {
    display: none;
  }
  .forbidden-root .left-side .content {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .forbidden-root .left-side .content .main-part {
    justify-content: start;
    overflow-y: auto;
  }
  .forbidden-root .left-side .content .main-part .label {
    padding-bottom: 20px;
  }
  .forbidden-root .left-side .content .main-part .message .title {
    padding-bottom: 30px;
  }
  .forbidden-root .left-side .content .main-part .message .text {
    padding-bottom: 20px;
  }
  .forbidden-root .left-side .content .main-part .message .error {
    padding-bottom: 20px;
  }
  .forbidden-root .right-side {
    display: none;
  }
}

/*  link color blue */
/* success state color */
/* warning state color (red) */
/* error state color (red) */
/* heading text color */
/* major border radius */

/* Import Ant Design styles by less entry */
/* variables to override above */
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  color: #2d3039;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body .layout-navbar-drawer .ant-drawer-body {
  padding-top: 40px;
  padding-right: 0;
  padding-left: 0;
}
.ant-notification .ant-notification-notice-message {
  color: #2d3039;
}
#portal-ui {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
}
#portal-ui .text-field,
#portal-ui .input {
  border: none;
  border-radius: 0;
  outline: none;
}
#portal-ui .text-field input,
#portal-ui .input input {
  border-top: none;
  outline: none;
  border-radius: 0;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #9da6c0;
}
#portal-ui .text-field input:hover,
#portal-ui .input input:hover,
#portal-ui .text-field input:active,
#portal-ui .input input:active,
#portal-ui .text-field input:focus,
#portal-ui .input input:focus {
  outline: none;
  box-shadow: none;
  border-bottom-color: #0084ff;
}
#portal-ui .with-tip {
  position: relative;
}
#portal-ui .with-tip::before {
  content: attr(data-tip);
  position: absolute;
  bottom: -60%;
  min-width: 50px;
  font-size: 10px;
  font-weight: 500;
  color: #9da6c0;
}
#portal-ui .tip-success-color::before {
  color: #36c535;
}
#portal-ui .tip-warning-color::before {
  color: #f85335;
}
#portal-ui .tip-error-color::before {
  color: #f85335;
}
#portal-ui .text-link {
  color: #9da6c0;
}
#portal-ui .text-link:hover,
#portal-ui .text-link:focus {
  text-decoration: underline;
}
.page {
  padding: 30px;
}
@media (max-width: 768px) {
  .page {
    padding: 20px;
  }
}
@media (max-width: 576px) {
  .page {
    padding: 5px;
  }
}
.icon > svg {
  fill: #b1b8cd;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.bi-icon-color .ant-progress-inner {
  background-color: #fcc;
}
.bi-icon-color .ant-progress-bg {
  background-color: #f85334;
}
.bi-icon-color > svg {
  fill: #f85334;
}
.tt-icon-color .ant-progress-inner {
  background-color: #b3e0ff;
}
.tt-icon-color .ant-progress-bg {
  background-color: #0084ff;
}
.tt-icon-color > svg {
  fill: #0084ff;
}
.hover-pointer {
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.hover-pointer:hover,
.hover-pointer:focus {
  cursor: pointer;
  opacity: 0.75;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.icon-hover {
  padding: 12px;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.icon-hover:hover,
.icon-hover:focus {
  background-color: #f4f7fc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.icon-hover.fill-primary:hover > svg,
.icon-hover.fill-primary:focus > svg {
  fill: #acc437;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.module-icon {
  color: #ffffff;
  font-family: 'Montserrat Alternates', sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.7px;
}
.bi-module-icon {
  color: #f85334 !important;
  font-family: 'Montserrat Alternates', sans-serif !important;
  font-size: 14px !important;
  font-weight: 700 !important;
}
.tt-module-icon {
  color: #0084ff !important;
  font-family: 'Montserrat Alternates', sans-serif !important;
  font-size: 14px !important;
  font-weight: 700 !important;
}
.arrow-icon-right {
  -webkit-transform: rotate(0.5turn);
          transform: rotate(0.5turn);
}
.arrow-icon-up {
  -webkit-transform: rotate(0.25turn);
          transform: rotate(0.25turn);
}
.arrow-icon-down {
  -webkit-transform: rotate(-0.25turn);
          transform: rotate(-0.25turn);
}
.ant-btn.btn-default {
  border: none;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #485053;
}
.header-dropdown .ant-dropdown-menu a,
.custom-dropdown .ant-dropdown-menu a {
  font-size: 14px;
  font-weight: 500;
}
.header-dropdown .ant-dropdown-menu .btn-default,
.custom-dropdown .ant-dropdown-menu .btn-default {
  border: none;
  margin: 0;
  padding-left: 0;
  font-size: 14px;
  font-weight: 500;
  box-shadow: none;
}
.header-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.custom-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover,
.header-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.custom-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover,
.header-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover a,
.custom-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover a,
.header-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover .btn-default,
.custom-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover .btn-default,
.header-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover a,
.custom-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover a,
.header-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover .btn-default,
.custom-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title:hover .btn-default {
  background-color: #f4f7fc;
  color: #2d3039;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.visible-sm {
  display: none;
}
@media screen and (max-width: 576px) {
  .visible-sm {
    display: inline;
    display: initial;
  }
}
@media screen and (max-width: 576px) {
  .hidden-sm {
    display: none !important;
  }
}
@media screen and (max-width: 768px) {
  .hidden-md {
    display: none !important;
  }
}
.text-center {
  text-align: center;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.google-places-autocomplete__suggestions-container {
  background-color: white;
  max-height: 200px;
  min-width: 300px;
  overflow-y: auto;
  padding: 0 10px;
  position: absolute;
  z-index: 5;
}

