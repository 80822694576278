@import (reference) '~antd/dist/antd.less';
@import (reference) '../../theme.less';

.back-to-origin {
  .ant-drawer-title {
    color: @text-accent-color;
  }

  .ant-drawer-body {
    padding: 0;
  }

  .ant-card-meta {
    padding-bottom: 10px;
  }

  .ant-radio-wrapper {
    padding-bottom: 10px;
  }

  .ant-btn {
    margin-top: 10px;
  }

  .ant-typography,
  .ant-card-meta-title,
  .ant-card-meta-detail {
    color: @text-secondary-color;
  }
}
