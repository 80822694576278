@import (reference) '../../theme.less';

.layout {
  .sider {
    box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.02), inset -2px 0 0 0 #ecedf2;
    background-color: @heading-color;

    .trigger {
      padding: 30px;
      font-size: 16px;
    }
  }

  .nav {
    .ant-menu-item {
      width: calc(100% - 1px);

      &::after {
        border-color: @primary-color;
        right: auto;
        left: 0;
      }

      span {
        font-size: 16px;
        font-weight: 500;
        font-family: @font-family;
        color: @text-primary-color;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        vertical-align: middle;
      }

      .anticon {
        font-size: 16px;
        vertical-align: middle;
      }
    }

    .ant-menu-item.ant-menu-item-selected {
      background-color: @bg-color-light;

      .icon svg {
        fill: @primary-color;
      }

      &.ant-menu-item::after {
        border-color: @primary-color;
        transform: none;
        opacity: 1;
        right: auto;
        left: 0;
      }
    }

    &.ant-menu-inline .ant-menu-item::after {
      right: auto;
      left: 0;
    }
  }

  .header {
    background-color: @heading-color;
    padding-left: 0;
    padding-right: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    position: relative;

    @media screen and (max-width: @screen-sm) {
      padding-left: 5px;
    }

    &::after {
      // shadow under header
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: transparent;
      box-shadow: 0 5px 15px 0 black;
      z-index: 30;
    }

    .header-first-section {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      @media screen and (max-width: @screen-md) {
        flex-basis: 100%;
      }

      .icon.trigger {
        width: 80px; // sider width

        @media screen and (max-width: @screen-sm) {
          width: 35px;
          padding-left: 5px;
          text-align: left;
        }
      }

      .header-search-area {
        padding-right: calc(25px - 12px);
      }
    }

    .header-second-section {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      border: 2px solid @bg-color-darker;
      border-top: none;
      border-bottom: none;
      padding-left: 25px;
      padding-right: 25px;

      @media screen and (max-width: @screen-md) {
        padding-left: 7px;
        padding-right: 7px;
        border-left: none;
      }

      @media screen and (max-width: @screen-sm) {
        border-right: none;
        padding-right: 0;
      }

      .header__user-name {
        font-family: @font-family;
        font-size: @font-size-base;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: @text-accent-color;
        margin-right: 10px;
        margin-left: 10px;

        @media screen and (max-width: @screen-md) {
          display: none;
        }
      }

      .icon img {
        padding: 4px 0;
      }

      .ant-avatar > img {
        object-fit: cover;
      }
    }

    .header-last-section:not(:empty) {
      padding-left: 15px;
      padding-right: 10px;

      @media screen and (max-width: @screen-sm) {
        padding-left: 5px;
        padding-right: 0;
      }
    }

    .company-logo-link {
      &:focus {
        text-decoration: none;
      }
    }

    .company-logo {
      font-family: @alternates-font-family;
      font-size: 18px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 2.5;
      letter-spacing: normal;
      text-align: left;
      color: @primary-color;
    }
  }
}
